import { reactive, toRefs, defineAsyncComponent } from "vue";
import { useStore } from "vuex";
import { useDebounce } from "@/hooks/core/useDebounce";
import API from "@/api/report/index";
import httpApi from "@/api/httpApi";
import { useMessage } from "@/hooks/web/useMessage";
import dayjs from "dayjs";
import { Search } from "@element-plus/icons-vue";
import { ElMessageBox } from 'element-plus';
import download from '@/utils/download';
export default {
  name: "Alarm",
  components: {
    // 自定义组件
    BasicTemplate: defineAsyncComponent(() => import("@/components/BasicTemplateTablePage/index.vue")),
    TypeSelect: defineAsyncComponent(() => import("@/components/TypeSelect/TypeSelect.vue")),
    TreeSelect: defineAsyncComponent(() => import("@/components/TreeSelect/TreeSelect.vue")),
    AlarmInfo: defineAsyncComponent(() => import("./components/AlarmInfo.vue"))
  },
  setup() {
    const store = useStore();
    const message = useMessage();
    const refData = reactive({
      alarmIds: [],
      loading: false,
      tableLoading: false,
      total: 0,
      current: 1,
      pageSize: 50,
      value: 1,
      infoVisible: false,
      activeInfo: {},
      searchForm: {
        schoolId: null,
        holdid: null,
        objectid: "",
        objectname: "",
        mdtid: null,
        alarmType: 0,
        alarmTime: [dayjs(new Date().getTime() - 60 * 60 * 24 * 3 * 1000).format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
        phone: ""
      },
      tableData: [],
      tableColumns: [{
        type: 'selection',
        title: '勾选',
        align: 'center',
        key: 'checkbox',
        width: 60,
        visible: true
      }, {
        title: "事件类型",
        align: "center",
        key: "alarmname",
        visible: true,
        width: 120
      }, {
        title: "IMIE号",
        align: "center",
        key: "mdtid",
        visible: true,
        width: 140
      }, {
        title: "设备名",
        align: "center",
        key: "objectname",
        visible: true,
        width: 120
      }, {
        title: "所属单位",
        align: "center",
        key: "holdname",
        visible: true,
        width: 140
      }, {
        title: "报警时间",
        align: "center",
        key: "recvtime",
        visible: true,
        width: 160
      }, {
        title: "报警说明",
        align: "center",
        key: "othervalue",
        visible: true,
        width: 140
      }, {
        title: '处理状态',
        align: 'center',
        key: 'result',
        visible: true,
        width: 120
      }, {
        title: '处理时间',
        align: 'center',
        key: 'handleTime',
        visible: true,
        width: 140
      }, {
        title: '处理人',
        align: 'center',
        key: 'userName',
        visible: true,
        width: 140
      }, {
        fixed: "right",
        title: "操作",
        align: "center",
        slots: {
          customRender: "operate"
        },
        key: "operate",
        visible: true,
        width: 120
      }
      // {
      //   title: '报警地址',
      //   align: 'center',
      //   key: 'address',
      //   slots:{customRender:'address'},
      //   visible: true,
      //   width:200
      // },
      // {
      //   title: '终端SIM卡号',
      //   align: 'center',
      //   key: 'phone',
      //   visible: true,
      //   width:200
      // },
      // {
      //   title: '开始报警时间',
      //   align: 'center',
      //   key: 'alarmBeginTime',
      //   visible: true,
      //   width:200
      // },
      // {
      //   title: '结束报警时间',
      //   align: 'center',
      //   key: 'alarmEndTime',
      //   visible: true,
      //   width:200
      // },
      ]
    });
    const handleSelectionChange = data => {
      refData.alarmIds = data.map(item => ({
        alarmid: item.id,
        objectid: item.objectid,
        alarmtype: item.alarmtype
      }));
    };
    //单个解除报警
    const deleteRealAlarm = async item => {
      const {
        id,
        objectid,
        alarmtype,
        alarmname,
        mdtid
      } = item;
      refData.alarmIds = refData.alarmIds.filter(item => id != item.id);
      ElMessageBox.confirm(`是否确认处理“${mdtid}设备-${alarmname}”`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true
      }).then(async () => {
        const {
          code,
          msg
        } = await httpApi.deleteRealAlarm({
          alarmid: id,
          objectid,
          alarmtype
        });
        message[code == 0 ? "success" : "warning"](code == 0 ? "设置成功" : msg);
        code == 0;
      }).catch(() => {
        // ElMessage.info("已取消退出");
      });
    };
    //批量报警处理
    const handleBatchRealAlarm = () => {
      ElMessageBox.confirm(`是否确认处理当前已选择的“${refData.alarmIds.length}个”报警`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true
      }).then(async () => {
        const {
          code,
          msg
        } = await httpApi.deleteRealAlarmBatch({
          deleteAlarm: refData.alarmIds
        });
        message[code == 0 ? "success" : "warning"](code == 0 ? "设置成功" : msg);
        code == 0;
      }).catch(() => {
        // ElMessage.info("已取消退出");
      });
    };
    const onSearch = async () => {
      //console.log(refData.searchForm.alarmTime)
      if (refData.searchForm.alarmTime === null) {
        refData.searchForm.alarmTime = ['', ''];
      }
      refData.loading = true;
      const params = {
        holdid: refData.searchForm.holdid,
        schoolId: refData.searchForm.schoolId,
        pageNo: refData.current,
        pageSize: refData.pageSize,
        objectname: refData.searchForm.objectname,
        mdtid: refData.searchForm.mdtid,
        alarmtype: refData.searchForm.alarmType <= 0 ? "" : refData.searchForm.alarmType,
        beginTime: refData.searchForm.alarmTime.length > 1 ? `${refData.searchForm.alarmTime[0]} 00:00:00` : "",
        endTime: refData.searchForm.alarmTime.length == 2 ? `${refData.searchForm.alarmTime[1]} 23:59:59` : "",
        phone: refData.searchForm.phone
      };
      const regPhone = /^1[3456789]\d{9}$/;
      try {
        if (params.beginTime == ' 00:00:00') throw {
          msg: "开始时间不能为空"
        };
        if (params.endTime == ' 23:59:59') throw {
          msg: "结束时间不能为空"
        };
        if (params.mdtid && params.mdtid.length > 20) throw {
          msg: "IMEI号错误，请输入正确IMEI号"
        };
        const {
          data,
          msg,
          code
        } = await API.getAlarmLog(params);
        refData.tableData = [];
        if (code > 0) {
          message.warning(msg);
        } else {
          refData.total = data.total;
          refData.tableData = data.list.map(item => ({
            ...item,
            result: item.alarmname.indexOf('提醒') != -1 || item.alarmname.indexOf('正常') != -1 || item.alarmname.indexOf('低电量') != -1 ? '无需处理' : item.result == 0 ? '未处理' : item.result == 1 ? '已处理' : item.result == 3 ? '超时处理' : '-',
            point: {
              lon: item.lon,
              lat: item.lat
            }
          }));
        }
        refData.total = data.total;
        refData.loading = false;
      } catch (error) {
        refData.loading = false;
        refData.total = 0;
        refData.tableData = [];
        message.warning(error.message);
      }
    };
    const [debounceOnSearch] = useDebounce(onSearch, 200);
    const currentChange = val => {
      refData.current = val;
      debounceOnSearch();
    };
    const pageSizeChange = val => {
      refData.pageSize = val;
      debounceOnSearch();
    };
    //清空
    const handleEmpty = () => {
      refData.searchForm = {
        holdid: null,
        schoolId: null,
        objectid: null,
        objectname: "",
        mdtid: null,
        alarmType: 0,
        alarmTime: ["", ""],
        phone: ""
      };
    };
    //导出
    const handleExport = async () => {
      refData.tableLoading = true;
      const params = {
        holdid: refData.searchForm.holdid || "",
        objectname: refData.searchForm.objectname,
        mdtid: refData.searchForm.mdtid || "",
        alarmtype: !refData.searchForm.alarmType ? "" : refData.searchForm.alarmType,
        beginTime: refData.searchForm.alarmTime.length > 1 ? `${refData.searchForm.alarmTime[0]} 00:00:00` : "",
        endTime: refData.searchForm.alarmTime.length == 2 ? `${refData.searchForm.alarmTime[1]} 23:59:59` : "",
        phone: refData.searchForm.phone
      };
      const data = await API.exportAlarmList(params);
      console.log(data);
      download.excel(data, '报警记录.xls');
      refData.tableLoading = false;
    };
    //组织数回调
    const handleClick = data => {
      if (data.type == "org") {
        refData.searchForm.holdid = data.id;
        refData.searchForm.schoolId = null;
      } else {
        refData.searchForm.holdid = data.parentId;
        refData.searchForm.schoolId = data.id;
      }
      debounceOnSearch();
    };
    // 初始化函数调用
    return {
      ...toRefs(refData),
      currentChange,
      pageSizeChange,
      debounceOnSearch,
      handleSelectionChange,
      deleteRealAlarm,
      handleBatchRealAlarm,
      handleEmpty,
      handleExport,
      handleClick,
      Search
    };
  }
};